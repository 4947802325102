@import "texts.module";

.tabIndicator {
  background-color: $primary-main;
}
.tabText {
  color: $black80;
}

.tabItem {
  display: flex;
  flex-flow: row;
}
.menuList {
  background: white;
  border: 1px solid $primary-extra-light;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  font-family: $secondary-font-family;
  font-size: 13px;
  line-height: 18px;
  color: $black90;
}
.menuItem {
  display: flex;
  flex-flow: row;
  .icon {
    margin-right: 15px;
  }
}
.activeMenuItem {
  @extend .menuItem;
  background: $primary-extra-light;
  color: $primary-main;
}

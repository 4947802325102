@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
@import "assets/fonts/Inter/inter.module.scss";
@import "variables.fonts.module";

h1,
h2,
h3,
h4 {
  font-family: $base-font-family;
  font-weight: 600;
  font-style: normal;
}
h1 {
  font-size: 32px;
  line-height: 42px;
}
h2 {
  font-size: 24px;
  line-height: 36px;
}
h3 {
  font-size: 18px;
  line-height: 27px;
}
h4 {
  font-size: 15px;
  line-height: 22px;
}
p,
div,
span,
label {
  font-family: $secondary-font-family;
}

@import "styles/variables.colors.module";

.listItem {
  height: 32px;
  line-height: 18px;
  font-size: 13px;
  color: $black80;
  padding: 0 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    path {
      fill: $black80;
    }
  }

  &:hover,
  &.selected {
    background-color: $primary-extra-light;
    color: $primary-dark;

    svg {
      path {
        fill: $primary-dark;
      }
    }
  }

  .iconContainer {
    min-width: 34px;
    display: flex;
    align-items: center;
  }

  .selectIconContainer {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .text {
    font-size: 13px;
    flex-grow: 1;
  }
}

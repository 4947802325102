@import "texts.module";

$paddingTableHead: 190px;
@function tableBodyScroll() {
  @return calc(100vh - #{$paddingTableHead});
}

.tableHead {
  .tableRow {
    height: 40px;
  }

  .tableCell {
    font-family: $secondary-font-family;
    font-size: 18px;
    line-height: 18px;
    color: $black90;
    border: 1px solid $black20;
    border-right: none;
    padding: 10px 5px;
    background-color: $white;

    cursor: pointer;
    user-select: none;

    &:last-child {
      border-right: 1px solid $black20;
      border-left: 1px solid $black20;

      width: 21px;
    }
  }
}

.cellTitle {
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.tableBody {
  .tableRow {
    cursor: pointer;
    height: 40px;
    width: 100%;

    .tableCell {
      font-family: $secondary-font-family;
      font-size: 16px;
      color: $black90;
      padding: 10px 5px;
      line-height: 18px;
      text-decoration: none;

      .multilineContainer {
        line-height: 190%;
      }

      &:last-child {
        width: 21px;

        .actionIcon {
          align-items: center;
          height: 39px;
          display: none;
        }
      }
    }
  }
}

table > thead > tr > {
  th:first-child {
    border-left: none !important;
  }

  th:last-child {
    border-right: none;
  }
}

.sortIcon {
  width: 10px;
  height: 10px;
  font-weight: bold;
  color: #5a5859;
  cursor: pointer;
}

.activeSortIcon {
  opacity: 1;
}

.inactiveSortIcon {
  opacity: 0.4;
}

.sortDescIcon {
  @extend .sortIcon;
  margin-top: 3px;
  line-height: 8px;
}

.tableBodyScroll {
  height: 100%;
}
.tableBodyScroll tbody {
  display: block;
  height: tableBodyScroll();
  overflow-y: auto;
}

.tableBodyScroll thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

@import "styles/variables.colors.module";

.paper {
  margin-top: -8px;

  .list {
    .divider {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

$width: 60;
$color: #d6d6d6;
$smallLoaderWidth: 40;

.ballLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  &.smallLoader {
    width: $smallLoaderWidth + 0px;
    height: ($smallLoaderWidth / 3) - 10px;
  }
  &.defaultLoader {
    width: $width + 0px;
    height: ($width / 3) - 10px;
  }
}
.ballLoaderBall {
  will-change: transform;
  height: ($width / 3) - 10px;
  width: ($width / 3) - 10px;
  border-radius: 50%;
  background-color: $color;
  position: absolute;
  animation: grow 1s ease-in-out infinite alternate;
  &.smallBall {
    height: ($smallLoaderWidth / 3) - 10px;
    width: ($smallLoaderWidth / 3) - 10px;
  }
  &.defaultBall {
    height: ($width / 3) - 10px;
    width: ($width / 3) - 10px;
  }

  &.ball1 {
    left: 0;
    transform-origin: 100% 50%;
  }
  &.ball2 {
    left: 50%;
    transform: translateX(-50%) scale(1);
    animation-delay: 0.33s;
  }
  &.ball3 {
    right: 0;
    animation-delay: 0.66s;
  }
}

@keyframes grow {
  to {
    transform: translateX(-50%) scale(0);
  }
}

@import "styles/variables.colors.module";

.spinner {
  color: $blue1;
  display: flex;
  align-items: center;
  flex-flow: row;
  .text {
    font-size: 13px;
    font-style: normal;
    color: $black80;
    padding-left: 13px;
  }

  .colorPrimary {
    color: $blue1;
  }
}

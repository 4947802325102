@import "styles/common.module.scss";

.snackBarContent {
  width: 420px;
  border: 1px solid $black40;
  border-radius: 1px;
  box-shadow: none;
  padding: 0px 24px;
  &.errorSnackBar {
    border: 1px solid $errorColor;
    color: $errorColor;
    background-color: $red-light;
  }
  &.successSnackBar {
    border: 1px solid $alertSuccessColor;
    background-color: $green-light;
    color: $gray2;
  }
}
.messageIcon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  flex-shrink: 0;
}
.messageWrap {
  display: flex;
  align-items: center;
  flex-flow: row;
}
.messageText {
  font-size: 13px;
}
.closeImg {
  cursor: pointer;
}

@import "texts.module";

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: $secondary-font-family;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border-style: unset;
}

.fullWidth {
  width: 100%;
}

.btn_default {
  @extend .btn;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  min-width: 93px;
  background: $blue1;

  &:disabled {
    background: $black20;
    box-shadow: none;
    color: $black40;
    cursor: default;
  }
}

.btn_large_default {
  @extend .btn_default;
  height: 42px;
  font-size: 18px;
  line-height: 42px;
  padding-left: 32px;
  padding-right: 32px;

  .leftIconContainer {
    padding-right: 10px;
  }
  .rightIconContainer {
    padding-left: 10px;
  }
}

.btn_medium_default {
  @extend .btn_default;
  height: 32px;
  font-size: 14px;
  line-height: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 24px;
  padding-right: 24px;

  .leftIconContainer {
    padding-right: 10px;
  }
  .rightIconContainer {
    padding-left: 10px;
  }
}
.btn_small_default {
  @extend .btn_default;
  height: 26px;
  width: auto;
  min-width: 66px;

  font-size: 13px;
  line-height: 26px;
  padding-left: 16px;
  padding-right: 16px;

  .leftIconContainer {
    padding-right: 8px;
  }
  .rightIconContainer {
    padding-left: 8px;
  }
}
.btn_loading_default {
  @extend .btn_default;
  &:hover {
    background: $primary-dark;
  }
  &:disabled {
    background: $primary-main;
    box-shadow: none;
    color: white;
    cursor: default;
  }
}
.btn_large_default_loading {
  @extend .btn_loading_default;
  height: 42px;
  font-size: 18px;
  line-height: 140%;
}
.btn_medium_default-loading {
  @extend .btn_loading_default;
  height: 32px;
  font-size: 14px;
  line-height: 140%;
}
.btn_small_default_loading {
  @extend .btn_loading_default;
  height: 26px;
  width: auto;
  min-width: 66px;

  font-size: 13px;
  line-height: 140%;
}
.btn_secondary {
  @extend .btn;
  font-weight: normal;

  box-shadow: none;

  min-width: 93px;
  background: #ffffff;
  border: 1px solid $primary-main;
  box-sizing: border-box;
  border-radius: 2px;
  color: $primary-dark;

  &:hover {
    background: $primary-extra-light;
    color: $primary-dark;
  }

  &:active {
    border-color: $primary-extra-light;
    background: $primary-extra-light;
    color: $primary-main;
  }

  &:disabled {
    border: 1px solid $black10;
    color: $black40;
    cursor: default;
    background: $black10;

    &:hover {
      background: $black10;
    }
  }
}

.btn_large_secondary {
  @extend .btn_secondary;
  height: 42px;
  font-size: 18px;
  line-height: 140%;
}

.btn_medium_secondary {
  @extend .btn_secondary;
  height: 32px;
  font-size: 14px;
  line-height: 140%;
}

.btn_small_secondary {
  @extend .btn_secondary;
  height: 26px;
  width: auto;
  min-width: 66px;
  font-size: 13px;
  line-height: 140%;
  border: 1px solid $black20;

  &:hover {
    border: 1px solid $primary-main;
  }

  &:disabled {
    border: 1px solid $black20;
  }
}
.btn_secondary_loading {
  @extend .btn;
  font-weight: normal;

  background: #ffffff;
  border: 1px solid $black20;
  box-sizing: border-box;
  border-radius: 2px;
  color: $black80;

  &:disabled {
    cursor: default;
  }
}
.btn_large_secondary_loading {
  @extend .btn_secondary_loading;
  height: 42px;
  font-size: 18px;
  line-height: 140%;
}
.btn_medium_secondary_loading {
  @extend .btn_secondary_loading;
  height: 32px;
  font-size: 14px;
  line-height: 140%;
}
.btn_small_secondary_loading {
  @extend .btn_secondary_loading;
  height: 26px;
  width: auto;
  min-width: 66px;

  font-size: 13px;
  line-height: 140%;
}
.btn_link {
  font-family: $secondary-font-family;
  font-style: normal;
  font-weight: normal;
  text-decoration: unset;
  cursor: pointer;
  color: $blue1;

  &:hover {
    text-decoration-line: none;
  }

  &:active {
    text-decoration-line: none;
  }
}

.btn_link_medium {
  @extend .btn_link;
  font-size: 13px;
  line-height: 140%;
  text-decoration: none;
}

.btn_link_large {
  @extend .btn_link;
  font-size: 15px;
  line-height: 140%;
}

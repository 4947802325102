@import "styles/common.module.scss";

body {
  margin: 0px;
  position: relative;
  color: $black90;
}

@media all and (min-width: 960px) {
  html {
    font-size: 14px;
  }
}

@media all and (max-width: 959px) and (min-width: 600px) {
  html {
    font-size: 12px;
  }
}

@media all and (max-width: 599px) and (min-width: 320px) {
  html {
    font-size: 10px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #2e2e2e;
  box-shadow: none;
  height: 50px;
}

::-webkit-scrollbar {
  width: 12px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
}

::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border-radius: 0;
}

::-webkit-scrollbar-corner {
  background-color: #c8c8c8;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  .content {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
}

@import "styles/common.module.scss";
@import "styles/variables.colors.module.scss";

.logoHeader {
  width: 100%;
  height: $logoHeight - 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: $white;
  .jummaLogo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    svg {
      height: 20px;
      width: auto;
    }
    .menuItem {
      padding-inline-start: 10px;
      color: $black90;
      font-size: 20px;
      text-decoration: none;
      &:hover {
        cursor: pointer;
        color: $black40;
      }
    }
  }
  .aiconLogo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    svg {
      height: 30px;
      width: auto;
    }
  }
}

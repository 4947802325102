@import "styles/variables.colors.module";

.root {
  display: flex;
  padding: 6px 24px;

  .container {
    color: $black90;
    cursor: pointer;
    line-height: 21px;
    height: 21px;
    display: flex;

    .textContainer {
      margin-right: 4px;
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;

      .text {
        font-size: 16px;
        white-space: nowrap;
      }
    }
  }
}

@import "texts.module";

.inputTextField {
  max-width: 420px;
  border: none;
  border-bottom: 1px solid $borderInput;
  box-sizing: border-box;
  border-radius: 2px;
  color: $black80;
  font-family: $secondary-font-family;
  font-weight: normal;
  font-size: 15px;
  &::before,
  &::after {
    border-bottom: none;
  }
  &:focus {
    outline: 0;
    background: transparent;
  }
}

.inputErrorField {
  @extend .inputTextField;
  border-bottom: 1px solid $errorColor;
}

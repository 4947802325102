@import "styles/variables.colors.module";
@import "styles/variables.fonts.module";
@import "styles/common.module.scss";

.wrapper {
  @extend .loggedOutWrapperLarge;
  .root {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 30px * 2);
    align-items: center;

    .title {
      max-width: 480px;
      font-size: 24px;
      font-family: $base-font-family;
      font-weight: $font-weight-600;
      color: $blue1;
      line-height: 36px;
      text-align: center;
    }

    .description {
      margin-top: 10px;
      font-family: $secondary-font-family;
      font-size: 13px;
      line-height: 18px;
      color: $black80;
      max-width: 420px;
      text-align: center;
    }

    .imageContainer {
      &.TOP {
        margin-bottom: 40px;
      }

      &.BOTTOM {
        margin-top: 80px;
      }
    }

    .footer {
      margin-top: 156px;
    }
  }
}

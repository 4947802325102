@import "styles/common.module.scss";

.popper {
}

.tooltip.forced {
  position: relative;
  background-color: $blue1;
  color: white;
  padding: 6px 10px;
}
.arrow.forced {
  color: $blue1;
}

.tooltipPlacementLeft,
.tooltipPlacementRight {
  margin: 0 8px;
}
.tooltipPlacementTop,
.tooltipPlacementBottom {
  margin: 8px 0;
}
.tooltipTitle {
  font-family: $secondary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
}
